export function buildQueryString(baseUrl, ...queryParams) {
  const urlParts = [baseUrl];
  let queryItems = [];
  let hasQueryParam = false;

  // Process each query parameter object and encode its key-value pair, excluding empty values
  queryParams.forEach((param) => {
    if (!param || !param.key || !param.value || param.value.length === 0) {
      return;
    }

    const encodedKey = encodeURIComponent(param.key);
    const encodedValue = encodeURIComponent(param.value);

    queryItems.push(`${encodedKey}=${encodedValue}`);
    hasQueryParam = true;
  });

  // Append encoded query items to the URL parts array, with a "?" only if there's at least one query parameter
  urlParts.push(hasQueryParam ? "?" : "");
  urlParts.push(queryItems.join("&"));

  return urlParts.join("");
}

import React from 'react';

export const Gallery = (props) => {
  const data = [
    {
      thumb: 'img/companies/adnsolar.webp',
      title: 'ADN Solar',
      url: '/argentina/buenos-aires/garin/ADN%20solar',
    },
    {
      thumb: 'img/companies/bremenergy.png',
      title: 'Brem Energy',
      url: '/argentina/buenos-aires/buenos-aires/BREM%20energy',
    },
    {
      thumb: 'img/companies/EcoEnergy.png',
      title: 'EcoEnergy',
      url: '/argentina/entre-rios/concepcion-del-uruguay/EcoEnergy',
    },
    {
      thumb: 'img/companies/cerroenergia.png',
      title: 'Cerro Energía Solar',
      url: '/argentina/buenos-aires/buenos-aires/CERRO%20energia%20solar',
    },
    { thumb: 'img/companies/juysolar.jpeg', title: 'JuySolar', url: '/argentina/jujuy/san-salvador-de-jujuy/JuySolar' },
    { thumb: 'img/companies/libersol.jpeg', title: 'Libersol', url: '/argentina/buenos-aires/buenos-aires/Libersol' },
    {
      thumb: 'img/companies/noxaenergy.webp',
      title: 'Noxa Energy',
      url: '/argentina/buenos-aires/buenos-aires/noxaenergy',
    },
    {
      thumb: 'img/companies/yerbabuenasolar.png',
      title: 'Yerba Buena Solar',
      url: '/argentina/tucuman/tucuman/Yerba%20Buena%20Solar',
    },
    {
      thumb: 'img/companies/suna.png',
      title: 'Suna Energías Renovables',
      url: '/argentina/santiago-del-estero/santiago-del-estero/Suna%20Energia%20Renovable',
    },
  ];

  const siteUrl = process.env.REACT_APP_DIRECTORY_URL;
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Proveedores</h2>
          <p>Estas son algunos de los proveedores destacados del Directorio</p>
        </div>
        <div className="row">
          {data.map(({ title, thumb, url }, index) => (
            <a href={siteUrl + url} key={index} className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <div className="portfolio-item cursor">
                <div className="hover-bg">
                  <div className="hover-text">
                    <h4>{title}</h4>
                  </div>
                  <img src={thumb} className="img-responsive" alt="Project Title" />
                </div>
              </div>
            </a>
          ))}
        </div>
        <a href={siteUrl} className="btn btn-custom btn-lg directory-button">
          Ir al Directorio
        </a>
      </div>
    </div>
  );
};

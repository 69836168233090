export const Footer = (props) => {
  const year = new Date().getFullYear();
  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          Copyright PowerHomeInitiative© {year} - info@poweriomeinitiative.com
        </div>
      </div>
    </div>
  )
}

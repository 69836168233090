export default async function getCities() {
  try {
    const response = await fetch(process.env.REACT_APP_DIRECTORY_URL + '/api/cities');

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error:', error);
  }
}

import { useState, useEffect } from 'react';
import { Navigation } from './components/navigation';
import { Header } from './components/header';
import { Features } from './components/features';
import { About } from './components/about';
import { Services } from './components/services';
import { Gallery } from './components/gallery';
import { Testimonials } from './components/testimonials';
import { Footer } from './components/footer';
import JsonData from './data/data.json';
import SmoothScroll from 'smooth-scroll';
import getProvinces from './api/provinces';
import getCities from './api/cities';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLandingPageData(JsonData);
    const fetch = async () => {
      try {
        setLoading(true);
        const provinces = await getProvinces();
        const cities = await getCities();
        setProvinces(provinces);
        setCities(cities);
      } catch (error) {
        return error;
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);

  useEffect(() => {}, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} provinces={provinces} cities={cities} loading={loading} />
      <Gallery />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      {/* <Services data={landingPageData.Services} /> */}
      {/* <Testimonials data={landingPageData.Testimonials} /> */}
      <Footer />
    </div>
  );
};

export default App;

import React, { useState } from 'react';
import { buildQueryString } from '../lib/helper';

export const Header = (props) => {
  const url = process.env.REACT_APP_DIRECTORY_URL;
  const [search, setSearch] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const finalUrl = buildQueryString(
    url,
    { key: 'search', value: search },
    { key: 'province', value: province },
    { key: 'city', value: city }
  );
  const { provinces, cities, loading } = props;

  const filteredCitiesByProvince = province ? cities.filter((city) => city.provinceID === province) : cities;

  const handleProvinceChange = (province) => {
    if (city) {
      const cityProvince = filteredCitiesByProvince.find((c) => c._id === city)?.provinceID;
      if (cityProvince !== province && province.length > 0) {
        setCity('');
      }
    }
    setProvince(province);
  };
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay-image">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-8 col-md-offset-2 intro-text">
                <h1 className="hidden-xs">
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <h2 className="visible-xs-block">
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h2>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <div className="search-control">
                  <div className="search-item">
                    <label htmlFor="provincias" className="control-label">
                      Provincia:
                    </label>
                    {loading ? (
                      'Cargando'
                    ) : (
                      <select
                        id="provincias"
                        className="form-control"
                        onChange={(e) => handleProvinceChange(e.target.value)}
                        value={province}
                      >
                        <option key={'todas'} value={''}>
                          {'-- Todas --'}
                        </option>
                        {provinces.map((provinceOption, index) => (
                          <option key={index} value={provinceOption._id}>
                            {provinceOption.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="search-item">
                    <label htmlFor="ciudades" className="control-label">
                      Ciudad:
                    </label>
                    {loading ? (
                      'Cargando'
                    ) : (
                      <select
                        id="ciudades"
                        className="form-control"
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                      >
                        <option key={'todas'} value={''}>
                          {'-- Todas --'}
                        </option>
                        {filteredCitiesByProvince.map((cityOption, index) => (
                          <option key={index} value={cityOption._id}>
                            {cityOption.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="search-item">
                    <label htmlFor="search" className="control-label">
                      Nombre de la Empresa:
                    </label>
                    <input
                      id="search"
                      type="search"
                      placeholder="Nombre de la Empresa"
                      className="form-control"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <a href={finalUrl} className="btn btn-custom btn-lg form-control">
                    <i className="fa fa-search"></i> Buscar
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
